import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import ProjectList from '../components/ProjectList'
import Pagination from '../components/Pagination'

export default class ProjectenPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressWpProject
    const { title: siteTitle } = data.site.siteMetadata
    const { title: pageTitle } = data.wordpressPage

    const {
      meta_description: metadesc,
      meta_tags: metatags,
      project_outro_text: ProjectOText,
      project_outro_btn1_text: ProjectOBtn1Text,
      project_outro_btn2_text: ProjectOBtn2Text,
    } = data.wordpressPage.acf

    return (
      <Layout>
        <Helmet>
          <title>{`${pageTitle} | ${siteTitle}`}</title>
          <meta name="description" content={metadesc} />
          <meta name="keywords" content={metatags} />
        </Helmet>
        <ProjectList
          posts={posts}
          ProjectOText={ProjectOText}
          ProjectOBtn1Text={ProjectOBtn1Text}
          ProjectOBtn2Text={ProjectOBtn2Text}
        />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    )
  }
}

ProjectenPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query ProjectenQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPage(wordpress_id: { eq: 24 }) {
      title
      content
      acf {
        meta_description
        meta_tags
        project_outro_text
        project_outro_btn1_text
        project_outro_btn2_text
      }
    }
    allWordpressWpProject(limit: $limit, skip: $skip) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
  }
`

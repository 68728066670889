import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import ProjectItem from './ProjectItem'

export default class ProjectList extends React.Component {
  render() {
    const {
      posts,
      ProjectOText,
      ProjectOBtn1Text,
      ProjectOBtn2Text,
    } = this.props

    return (
      <>
        <section className="section projecten">
          <div className="container">
            <div className="columns is-centered ">
              <div className="column is-10">
                <div className="columns project-overview is-variable is-6 is-multiline">
                  {posts.map(({ node: post }) => (
                    <div className="column is-4" key={post.id}>
                      <ProjectItem post={post} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content section has-text-centered">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-8 has-text-centered">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: ProjectOText }}
                />
                <div className="cta-double">
                  <Link to="/energetisch-renoveren" className="cta-link">
                    {ProjectOBtn1Text}
                  </Link>
                  <Link to="/contact" className="cta-link">
                    {ProjectOBtn2Text}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

ProjectList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_project {
    id
    title
    slug
    categories {
      name
    }
    acf {
      img_project_overview {
        alt_text
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
